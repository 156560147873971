export default function TC() {
	return <>
	<p><strong>TERMS AND CONDITIONS</strong></p>
<ol style={{display:'list-item'}}>
<li>All efforts will be made to complete the tour within the stipulated time as per the itinerary, however, we may reschedule the sightseeing days subject to WEATHER CONDITIONS/STRIKES &amp; to ensure smooth execution of tours. We shall not be responsible for any cancellation of boats due to bad weather or for any administrative reasons beyond the control of our management. CANCELLATION DUE TO WEATHER CONDITIONS/STRIKES/ADMINISTRATIVE RESTRICTIONS ARE BEYOND THE CONTROL OF THE MANAGEMENT AND THE MANAGEMENT WILL NOT BE LIABLE FOR SUCH INSTANCES.</li>
<li>AVAILABILITY AND USAGE OF VEHICLE - Visit to Museums will be for 60 &ndash; 75 minutes and for beaches it will be for 60 &ndash; 180 minutes. The Tour Coordinator/ Driver will advise a suitable reporting time to facilitate the visit to various spots as per the timings mentioned by the local administration. As per the hotels parking policy, the maximum waiting time outside the hotel will be 30 minutes after which the vehicle will leave the premises and will report back only after guests are ready to depart. The vehicle provided is as per itinerary (point to point basis) and any extra usage (not mentioned in the itinerary) will be chargeable. The same is not available at the discretion of the guest. At total discretion, vehicle charges are separate and in case of such requirements, please contact the tour coordinator.</li>
<li>AC in the vehicle will be switched on while the vehicle is on the move only and will be switched off when the halt exceeds 02 minutes. AC will not be switched on when the vehicle is parked.</li>
<li>Light and Sound show (Cellular Jail and Ross Island) is subject to weather conditions. If the same is interrupted due to bad weather, no refund will be provided by A&amp;N Administration (Dept of Tourism) or by us.</li>
<li>Closure of venture/tourist spot by the Government on the specific day is beyond our control and we will not be responsible for it. However, the same place will be covered on another day or will be compensated with a different spot.</li>
<li>Most of the ventures in Port Blair are closed on Monday and Ross Island and North Bay is closed on Wednesday. (MAY CHANGE FROM TIME TO TIME AT THE DISCRETION OF THE GOVT)</li>
<li>Havelock &amp; Neil Island have very limited ATM AND INTERNET facilities as on date. Kindly carry sufficient cash to settle your extras bills.</li>
<li>COMPLIMENTARY &ndash; As the word denotes, anything mentioned as complimentary is at the discretion of the service provider for which no amount is charged and thus is nonrefundable. Due to bad weather or closure of any point if complimentary services are not provided, it will be non-refundable and cannot be claimed against any other activity</li>
<li>Hotel check-in timings are between 1000 &ndash; 1100 Hours, varies from hotel to hotel. Hotel check-out timings are between 0800 &ndash; 0900 Hours, varies from hotel to hotel. Any request for early check-in or late check out have to made directly to the hotel and acceptance/denial/additional payment will be applicable as per policies of the hotel management. When the flight timings are in the afternoon/evening, the guests can directly request the hotels for late checkout and can pay any charges, if applicable. In case, the guests opt for further sightseeing due to late flights, additional charges as per fare sheet will be collected.</li>
<li>In case of Guests Checking in late, Complimentary breakfast (if applicable), will be provided by hotel on the following day in the morning. All meals will be served at the main restaurant of the hotel. The meal will be served in buffet style and anything ordered beyond the menu of the buffet will be considered as A-la-carte and the amount so incurred to be paid to the hotels directly. In case of early tours/departure, the reception is to be informed the previous day evening about the same for which the hotels will provide an alternate or packed breakfast.</li>
<li>For all half-day and day-long tours guest are suggested to carry enough water bottles, snacks and change over dress along with them. It is advisable to carry change over clothes for all beach visits. Collecting dead shells from beaches is not permitted and the same will be checked during scanning of luggage at airport.</li>
<li>We will not be responsible for any loss of any valuable/belongings during the trip, please take care of baggage count at all time.</li>
<li>All travel by ferry requires reporting to the embarkation point minimum 60 minutes before the scheduled time of departure as mentioned in the ticket. Liability of late reporting rests on the guests.</li>
<li>Jolly Buoy/Red Skin Island (closed on Monday) &ndash; Carrying of ID cards is compulsory. Guests are advised not to carry any poly bags/plastic items as the same is banned. It is better to carry eatables in tiffin/boxes to avoid inconvenience. The forest department will issue empty water bottles and guests have to shift water from plastic bottles before departure.</li>
<li>Visit to Baratang (closed on Monday) &ndash; Carrying of ID cards is compulsory. The visit to Baratang takes almost 12 hours as the vehicles are permitted to enter the restricted forest in Convoy timing Slots. AC might be switched off while travelling through the restricted area to facilitate the driver to maintain the speed limit and due to the terrain. On reaching Middle Strait, guests have to cross over to Baratang in a Govt Ferry to take the Fiber Boat to Limestone Cave or Mud Volcano. The Govt ferry ticket (generally Rs 10 per head) has to be paid by the guests only. PLEASE NOTE &ndash; PHOTOGRAPHY/VIDEOGRAPHY IN ANY WAY INSIDE THE RESTRICTED FOREST AREA IS STRICTLY PROHIBITED AND IS PUNISHABLE BY LAW.</li>
<li>Ross Island (closed on Wednesday) and North Bay: Entry fee is charged while entering both the islands (generally Rs 10-30 per head) and the same has to be paid by the guests directly which is not included in the package amount.</li>
<li>Visit to Elephant Beach/Light House Point: Guests will be required to report to the ferry point and a fiber boat with the capacity of 10/5/8 will ferry the guests to Elephant Beach and back. Snorkeling session for 05 minutes is provided as complimentary to the guests and the same is non-refundable.</li>
<li>Visit to Diglipur/Ross Smith/Kalipur/Karmatang/mangrove Walkway &ndash; Carrying of ID cards is compulsory. Visit to Ross Smith Island(closed on Tuesday) requires a minimum of 03 days. PLEASE NOTE &ndash; PHOTOGRAPHY/VIDEOGRAPHY IN ANY WAY INSIDE THE RESTRICTED FOREST AREA IS STRICTLY PROHIBITED AND IS PUNISHABLE BY LAW.</li>
</ol>
<p><strong>PAYMENT POLICY:</strong></p>
<ul style={{display:'list-item'}}>
<li>All bookings will be accepted only on payment of 50% of the total package/tour amount or as mentioned in the final package itinerary. 50% IS A TENTATIVE FIGURE, HOWEVER, IF THE HOTELS DEMAND FULL PAYMENT, THE SAME WILL BE COMMUNICATED TO THE CLIENT/GUEST AND THE PAYMENTS TO BE MADE ACCORDINGLY.</li>
<li>Balance amount has to be paid on the day of arrival to Andamans. Any delay in making the balance payment will result in discontinuation of the services. Hence, advance payment to be made only when the payment policy is accepted.</li>
<li>THE CLIENT(S) OR THE GUEST(S) ARE COMPLETELY LIABLE FOR ANY DISCONTINUATION OF TOURS FOR NOT CLEARING THE DUES ON THE DAY OF ARRIVAL ITSELF.</li>
</ul>
<p><strong>REFUND/CANCELLATION POLICY:</strong></p>
<ul style={{display:'list-item'}}>
<li>Hotel Policy &ndash;</li>
</ul>
<ol >
<li>Before 60 Days of Booking &ndash; 90% will be refunded.</li>
<li>Before 30 Days of Booking - 50% will be refunded.</li>
<li>Within 30 Days of Booking - No Refund.</li>
<li>Change of hotels due to any reasons will not be possible after checking in or within 30 days of date of checking in. it is advised to ascertain the quality of rooms and services before confirming the hotels to avoid inconvenience at a later stage.</li>
</ol>
<ul >
<li>Private Ferry tickets &ndash;</li>
</ul>
<ol >
<li>Before 48Hrs of Departure - Rs150 + Taxes are applicable</li>
<li>Before 24Hrs of Departure - 60% + Taxes are applicable,</li>
<li>WITHIN 24 Hrs of Departure - No Refund.</li>
</ol>
<ul style={{display:'list-item'}}>
<li>Refund procedures &ndash;</li>
</ul>
<ol >
<li>For cancellation received before 35 days of arrival - 99% refund;</li>
<li>Between 20 to 35 days of arrival - 50% refund;</li>
<li>Less than 20 days in advance of arrival - No refund.</li>
<li>No refund on travel bookings between 15th December &ndash; 15th January</li>
<li>No refunds for unused nights or early check-out.</li>
<li>No refunds would be offered on the Ferry tickets. If already purchased</li>
</ol>
<p><strong>&nbsp;</strong></p>
<p><strong>THE MANAGEMENT RESERVES THE RIGHT TO CHANGE/AMEND THE ABOVE TERMS AND CONDITIONS WITHOUT PRIOR. DISPUTES, IF ANY, ARE SUBJECT TO THE JURISDICTION OF ANDAMAN ONLY</strong></p>
<p>&nbsp;</p>
	</>
}