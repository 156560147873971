import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';

export default function SuccessModel(props) {
 
  return (
    <React.Fragment>
     
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={props.open}
       onClose={()=>props.offrun()}
        sx={{ display: 'flex',  maxWidth: 2000,justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: 2000,
            borderRadius: 'md',
            p: 3,
            bgcolor:'white',
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              bgcolor: 'orange',
            }}
          />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="green"
            fontWeight="lg"
            mb={1}
          >
           Your Booking is Confirmed!
          </Typography>
          <Typography id="modal-desc" textColor="green">
         
         <br />
         We have sent a confirmation voucher to your mail!
         <br />
         <br />
         </Typography>
         <Typography id="modal-desc" textColor="green">


        Thank you for choosing <br />
        Andaman Mangroves Holidays for your Andaman Trip!
          </Typography>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}