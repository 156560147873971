import Card from "./card/card";
import Hbooknow from "./Header/Hbooknow";
import Banner from "./Header/Hhome";
import Nav from "./nav";
import './css/pd.css'
import Tbg from './img/BookTourBanner.jpg'
import { useEffect, useState } from "react";
import Footer from "./footer";
import axios from "axios";
import WhatsApp from "./Whatsapp";
import { Helmet } from "react-helmet";
export default function PackageD() {
  useEffect(()=>{window.scrollTo(0,0)},[])
  const [api,setapi]=useState([{
    tour:''
   }]);
   
   useEffect(()=>{
    
    axios.get('https://www.andamanmangroves.com/api/TourPackage.php').then((res) => {
      const d1 = eval(res.data)
     
      d1.sort(function(a,b){
        return b.id-a.id;
      })
      
      setapi(d1);
     
      
      
      })
   },[])
    return<>
    <Helmet>
    <title> Discover Andaman's Wonders: Best Tour Packages for Honeymoon, Adventure, and Family Vacations</title>
<meta name="description" content=" Explore Andaman's beauty with our budget to luxury tour packages for families,
couples, and adventurers. Book now for unforgettable experiences! "/>
    </Helmet>
    <div className="container">
      <Nav />
      <div className="tour_page right-sidebar">
       <Banner current="Package Tour" img={Tbg} name="Package Details"/>
       <div className="wrap">
            <div className="wrap_float"style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between'}}  >
              {api.map((ele)=>{
               
                return  <div className="card"  style={{width:'auto',margin:"20px"}} >
                <Card menu_name={ele.menu_name} strp={ele.startingp} day={ele.day} night={ele.night} url={ele.url} img={ele.img}/></div>
              })}
             
              
              </div></div>
                
        
      </div>
         <WhatsApp/>         
    <Footer/>
    </div>
   
   
  </>
}