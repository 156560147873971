import axios from "axios";
import { useEffect, useState } from "react";
import BlogCard from "./Blogcard";
import Card from "./card/card";
import Footer from "./footer";
import Lblogimg from "./img/Live Blog Banner.jpg";
import Banner from "./Header/Hhome";
import Nav from "./nav";
import WhatsApp from "./Whatsapp";
import { Helmet } from "react-helmet";
export default function LiveBlog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [api, setapi] = useState([
    {
      url: "",
      img: "",
      date: "",
    },
  ]);

  useEffect(() => {
    axios.get("https://www.andamanmangroves.com/api/blog.php").then((res) => {
      const d1 = eval(res.data);

      d1.sort(function (a, b) {
        return b.id - a.id;
      });

      setapi(d1);
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Unveiling the Hidden Gems of Andaman: Explore the Latest Trends and
          Insider Tips on our Andaman Blog
        </title>
        <meta
          name="description"
          content=" Stay Updated on the Latest Andaman Trends and Insider Tips with our Informative and
Engaging Andaman Blog. Discover the Best of Andaman Now!"
        />
      </Helmet>
      <div className="container">
        <Nav />
        <div className="tour_page right-sidebar">
          <Banner current="Live Blog" img={Lblogimg} name=" Blog" />

          <div
            style={{
              marginTop: "40px",

              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {api.map((val) => {
              return (
                <BlogCard heading={val.url} date={val.date} img={val.img} />
              );
            })}
          </div>
        </div>
        <WhatsApp />
        <Footer />
      </div>
    </>
  );
}
