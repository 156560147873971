export default function Hbooknow(props) {
  var img=props.img&&props.img.toLowerCase();

  img=props.img&&img.replaceAll(" ","-")
    return(
      <>
     
        <div
        className="tour_page_head"
        style={{
          marginTop:'70px',
          width:'100%',
         height:'500px',
          backgroundImage:
            `url(https://www.andamanmangroves.com/api/image/${img})`,
            backgroundSize:'cover'
        }}
      >
        
        {/* .text_swi{
    position: absolute;
color:#ffff;
top:0%;
left:0%;
    width:100%;
    height:600px;
   background-color: rgba(22, 41, 84, 0.585);
} */}
        <div className="breadcrumbs" >
          <div className="wrap">
            <div className="wrap_float">
            
            </div>
          </div>
        </div>
        <div className="header_content" id="head"  style={{position:'absolute',top:'0%',paddingTop:'90px',
      left:'0%',width:'100%', height:'500px',backgroundColor: 'rgba(22, 41, 84, 0.585)'
      }}>
          <div className="wrap">
            <div className="wrap_float">
              <div className="top-info">
                <p className="country">{props.tour}</p>
                <h1 className="tour_title">{props.menu_name}</h1>
              </div>

              <div className="bottom-info">
                <div className="bottom-info-left">
                  <div className="search-tour">
                    <div className="search-form">
                      <div className="date-col">
                        <div className="label">Nights</div>
                        <div className="date_div">
                          <input type="date" className="mobile-input" />
                          <div className="day">{props.night}</div>
                        </div>
                      </div>
                      <div className="date-col">
                        <div className="label">Days</div>
                        <div className="date_div">
                          <div className="day">{props.day}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-info-right">
                  <div className="info">
                   
                    <div className="cost">
                      Starting from <span>₹</span>{props.strp}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      

      </>
    );
}