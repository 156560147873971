import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import StarIcon from "@mui/icons-material/Star";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation } from "swiper";
// import required modules
import { Autoplay, EffectFade } from "swiper";
import "./css/feedback.css";
import axios from "axios";
export default () => {
  const [scrwidth, chwidth] = useState(window.innerWidth);
  const [row, setrow] = useState(3);
  useEffect(() => {
    axios
      .get(
        "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=YourApiKeyHere",
        { params: { ID: 12345 } }
      )
      .then(function (response) {
       
      })
      .catch(function (error) {
      
      });
    if (scrwidth <= 1200 && scrwidth >= 700) setrow(2);
    if (scrwidth < 700) setrow(1);
    if (scrwidth > 1200) setrow(3);
  }, [scrwidth]);
  return (
    <>
      <div className="container_fe">
        <div className="header_fe">
          <h2>What Our Client Say About Us</h2>
        </div>
        <div className="swiper_fe">
          <Swiper
            slidesPerView={row}
            spaceBetween={25}
            modules={[EffectFade, Autoplay]}
            speed={3000}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a-/AD5-WCmnsWH58inwBYisbVFX201R9pXx2ewvwboQGQEquw=w75-h75-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    Fantastic end to end support and service from Raja and his
                    team. It was logistically well coordinated trip overall with
                    little to no need to follow up ever. The itinerary was put
                    together in a jiffy (and at a fraction of estimates provided
                    my other agents) but they didn’t drop the ball for a moment.
                    I’m recommending them to everyone planning a trip down the
                    Andamans.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>abhishek debnath</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>{" "}
            </SwiperSlide>

            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a-/AD5-WCkOv5XmMjNI1HuvhZfbkotKKleWkxjNzWkfnjrDleE=w75-h75-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    Andaman was a really good experience with family and Andaman
                    Mangroves planned everything to perfection. Raja coordinated
                    with us every day and the driver assigned to us was very
                    punctual and sincere. The whole itinerary matched our
                    expectations and included beaches, monuments, water
                    activities and visit to other islands near by like Havelock.
                    Overall, highly recommend Raja and Andaman Mangroves.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>kevin dsouza</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>{" "}
            </SwiperSlide>
           
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a-/AD5-WClRh5aekZNHmmlNsA7FzX1Yuvuv-IlzzwrERvFwAA=w75-h75-p-rp-mo-br100"
                   alt="img"
                    width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    Had a great experience with Andaman Mangroves Holidays. Mr.
                    Raja took good care of us and was in constant touch with us
                    through out our stay. Keep up the good work team!
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>Vishaal Swaminathan</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>{" "}
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp4Yhz3mw3Xk6s5oqVVsLz-coGGMFp17PXHjGbWX=w75-h75-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    Excellent experience with Andaman Mangroves
                    Holidays.Raja,the owner has arranged our tour very
                    systematically and made it hassle free.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>NAMRATA BAISHYA</p> 
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp4r2DNQuip5yFeDtpplhhk93BhpoIYayirNXY0u=w75-h75-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    He's the best tour operator I have even seen in Andaman.
                    He's a must try if you are planning for a trip to Andaman.
                    GREAT HOSPITALITY BROTHER. THANK YOU. YOU HAVE BEEN GREAT.
                    KEEP UP THE GOOD WORK.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>Kotteswari Nagarajan</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp7DvC7jiTYqZEJHpQ3MIhRvsikR7uJ9jr23_mve=w120-h120-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                    
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    It was one of the best trip. Thanks Mr. Raja and his team
                    for making my trip so comfortable. Being traveling two
                    sisters alone safety was at most priority, they took very
                    best care. Very punctual and efficient and informative. The
                    vehicles were very clean and comfortable.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>archna</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp7NEviZPzJhtpi1i3D3dJ4X0fCOTKqUMWeuy4sI=w120-h120-p-rp-mo-br100"
                  alt="img" 
                  width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    It was a memorable vacation with full enjoyment for the
                    complete family. The arrangements made by the team were
                    superb and willingness to make your stay more comfortable
                    and hassle free is appreciable.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>Sumit Jain</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp7uDL92T-3w76jNSJn4t0FXGLJ0IqdVxkuEg0cd=w75-h75-p-rp-mo-br100"
                  alt="img" 
                  width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                    If I have to summarise the experience in one word it was
                    mind-blowing! This was my second time with Andaman
                    Mangroves. The first time was way back in 2013 and I must
                    say the experience has only become better. I was travelling
                    with two tiny kids this time and I had offloaded all the
                    planning to Mr. Raja. Every tiny thing was taken care and we
                    had a splendid time at this tropical paradise.
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>Vijith Kini</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card_fe">
                <div className="img_fe">
                  <img
                    src="https://lh3.googleusercontent.com/a/AEdFTp5X4tBkYya0tUK5fpJI0UaqXgesGab0xUH1fneX=w120-h120-p-rp-mo-br100"
                   alt="img" 
                   width={75}
                  />
                </div>
                <div className="text_c_fe">
                  <p>
                  Next level experience.. trust value person Raja bro... And pls dnt have any doubt juz contact Raja bro... He wil take care of uuuu.... Very much satisfy with care and safety...
                  </p>
                </div>
                <div className="rating_fe">
                  <div className="name">
                    <p>nagoor meeran</p>
                  </div>
                  <div className="rating">
                    {[1, 2, 3, 4, 5].map(() => {
                      return <StarIcon style={{ color: "orangered" }} />;
                    })}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* <div className="elfsight-app-4dddf75a-18c5-49ce-8e76-8bdc616e22d0" ></div> */}
        </div>
      </div>
    </>
  );
};
