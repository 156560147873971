import logo from './logo.svg';
import './App.css';
import TourPackage from './component/TourPackage';


import { Route, Routes } from 'react-router-dom';
import Home from './component/Home';
import TourDetails from './component/Tourdet';
import PackageD from './component/PackageD';
import BookCab from './component/Bookacab';
import Blog from './component/Blog';
import LiveBlog from './component/LiveBlog';
import PP from './component/PP.jsx'
export default function Routing() {
    return <>
    <Routes>
    
        <Route   path="*" element={<Home/>}/>
        <Route  exact path="/" element={<Home/>}/>
        <Route path="/bookcab" element={<BookCab/>}/>
        <Route path="/PP" element={<PP/>}/>
       
        <Route  path="/tourdetails" element={<PackageD/>}/>
        <Route  path="/tour" element={<PackageD/>}/>
        <Route  path="/tourdetails/:url" element={<TourPackage/>}/>
        <Route  path="/tour/:url" element={<TourPackage/>}/>
        <Route path="/blog/:url" element={<Blog/>}/>
        <Route path="/blogdetails" element={<LiveBlog/>}/>
    </Routes>
    </>
}