import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState,useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from "react-html-parser";
export default function TourDetails(props) {
  var data=props.data
  const[open,setopen]=useState(false);

    const changeclass=(e)=>{
        setopen(!open)
        if(document.getElementById(props.id).classList.contains("active")){
         
             document.getElementById(props.id).classList.remove("active");
             document.getElementById(props.id+"con").style.display="none"
           
        }
        else{
          
     
             document.getElementById(props.id).classList.add("active");
             document.getElementById(props.id+"con").style.display="block"
        }
        
    }
   
    
    return (<>
    
    
   
    <div className="programm" id="programm-days">
      <div className="day_item" >
        <div className="day_item-head " id={props.id} onClick={(e)=>changeclass(e)}>
          <div className="preview" id={"pr"+props.id}>
            <div className="image" id={"im"+props.id}>
             {props.ind}
            </div>
            <div className="p" id={"p"+props.id}>{props.day} : {props.title}</div>
          </div>
          <div className="_title"></div>
          <div className="element" >
          {open?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
          </div>
        </div>
        
        <div className="day_item-body" id={props.id+"con"} >
          <div className="text">
          {ReactHtmlParser(data)}
          </div>
      
        </div>
      </div>
     
    </div>
   
  </>)
}