import './css/blogcard.css'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
export default function BlogCard(props) {
const navigate=useNavigate();
var img=props.img&&props.img.toLowerCase();

 img=props.img&&img.replaceAll(" ","-")
    return <>
    <div className="container_bc" onClick={()=>navigate('/blog/'+props.heading)}>

        <div className="img_bc">
            <img width={'100%'} height={'100%'} src={" https://www.andamanmangroves.com/api/image/"+img} alt="blog" />
            <h6><CalendarMonthIcon sx={{width:15}}/> {moment(props.date).format("DD MMMM Y")}</h6>
        </div>
        <div className="txt_bc"><h4>{props.heading.slice(0,50)}{props.heading.length>50&&'...'}</h4></div>
    </div>
    </>
}