import "./css/format.css";
import * as React from "react";



import Modal from '@mui/joy/Modal'
import { ModalClose, ModalDialog, Sheet } from "@mui/joy";








export default function FooterM(props) {

  

      
      

  return (
    <>


    
  <React.Fragment>
    <Modal open={props.open}  onClose={() => props.stateChange(false)}>
      <ModalDialog
        aria-labelledby="basic-modal-dialog-title"
        aria-describedby="basic-modal-dialog-description"
        sx={{
          maxWidth: 1050,
         
        
          height: 600,
        
          borderRadius: 'md',
          overflowY:'scroll',
          overflowX:'hidden',
          border:'none',
          m:0,
          boxShadow: 'lg',
        }}
      >
         <Sheet
      variant="outlined"
      sx={{
        backgroundColor:"white",
        maxWidth: 900,
        borderRadius: 'md',
         p: 3,
        boxShadow: 'lg',
      }}
    >
         <ModalClose
        variant="outlined"
        sx={{
          top: 'calc(-1/4 * var(--IconButton-size))',
          right: 'calc(-1/4 * var(--IconButton-size))',
          boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
          borderRadius: '50%',
        color:'white',
        backgroundColor:'red',
          zIndex:1000,
         transform:'translateX(00px)',
          bgcolor: 'orangered',
        }}
      />

        {props.data}
</Sheet>

      </ModalDialog>

    </Modal>

  </React.Fragment>
 
     



     
    </>
  );
}

