export default function CP(){

    return <>
      <p><strong>REFUND/CANCELLATION POLICY:</strong></p>
<ul style={{display:'list-item'}}>
<li>Hotel Policy &ndash;</li>
</ul>
<ol >
<li>Before 60 Days of Booking &ndash; 90% will be refunded.</li>
<li>Before 30 Days of Booking - 50% will be refunded.</li>
<li>Within 30 Days of Booking - No Refund.</li>
<li>Change of hotels due to any reasons will not be possible after checking in or within 30 days of date of checking in. it is advised to ascertain the quality of rooms and services before confirming the hotels to avoid inconvenience at a later stage.</li>
</ol>
<ul style={{display:'list-item'}}>
<li>Private Ferry tickets &ndash;</li>
</ul>
<ol>
<li>Before 48Hrs of Departure - Rs150 + Taxes are applicable</li>
<li>Before 24Hrs of Departure - 60% + Taxes are applicable,</li>
<li>WITHIN 24 Hrs of Departure - No Refund.</li>
</ol>
<ul style={{display:'list-item'}}>
<li>Refund procedures &ndash;</li>
</ul>
<ol >
<li>For cancellation received before 35 days of arrival - 99% refund;</li>
<li>Between 20 to 35 days of arrival - 50% refund;</li>
<li>Less than 20 days in advance of arrival - No refund.</li>
<li>No refund on travel bookings between 15th December &ndash; 15th January</li>
<li>No refunds for unused nights or early check-out.</li>
<li>No refunds would be offered on the Ferry tickets. If already purchased</li>
</ol>
<p>&nbsp;</p>
    </>
    }