import "./card.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
export default function Card(props) {
  const navigate=useNavigate()
  var img=props.img&&props.img.toLowerCase();

 img=props.img&&img.replaceAll(" ","-")

  return (
    <div className="container_cr">
       
      <div className="img">
        <img
          src={"https://www.andamanmangroves.com/api/image/"+img}
         
          alt=""
        />
      </div>
      <div className="lb_cr"><p>{props.day} days {props.night} nights</p></div>
      <div className="content"><h3>{props.menu_name}</h3></div>
      <div className="foo">
        <div className="foo-left">
          <div className="buttonc" style={{margin:10}}>
            <button
               className="btn cusbtn button "
               data-href="#book-now"
              style={{ width: "55%", height: "40px", borderRadius: 10,color:'orangered',fontSize:'15px' ,display:'flex',justifyContent:'space-around',alignItems:'center'}}
           onClick={()=>navigate("/tour/"+props.url)}
           >
              Book Now <ArrowForwardIcon  style={{ width: "10%"}}/>
            </button>
          </div>
        </div>
        <div className="foo-right">
          <div className="strpr">
            <span className="fr">From</span> <br />
            <h6 className="pr-fo" style={{ color: "orangered" }}>
            ₹{props.strp}&nbsp;
            </h6>
        
            Per Person
          </div>
        </div>
      </div>
    </div>
  );
}
